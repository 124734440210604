import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  PropertyActions,
} from './actions';
import { PropertyTypes } from './types';

const initialState: PropertyTypes = {
  property: undefined,
  loading: true
};

export const propertyReducer = createReducer(initialState, handleAction => [
  handleAction(PropertyActions.property.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(PropertyActions.property.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.property = payload
    }),
  ),
  handleAction(PropertyActions.property.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
]);
