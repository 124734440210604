export const About = () => {
    return (
      <>
        <div id="about" className="buy-wrap wow fadeInUp" style={{ background: "white" }}>
          <div className="container">
            <div className="title">
              <h1 style={{ color: "black" }}>About Us</h1>
            </div>
            <p style={{ color: "black" }}>
              S.A.S International Real Estate prides itself as one of the
              leaders in the property whether you are looking to buy, rent,
              sell, and investment opportunities offering to expatriates or
              locals in terms of residential, commercial luxury and premium
              property. We cater to every requirement from individuals to
              companies. We work hard on finding the best investment opportunity
              for our clients and provide the best service possible. Our agency
              has the most respected and highly experienced Dubai property
              consultants with a vast knowledge and professional in dealing with
              resale and new properties in all the major freehold areas.
              <br />
              <br />
              S.A.S International Real Estate offers a large selection of
              properties available including villas, apartments, townhouses,
              holiday homes and offices. We specialize in the most comprehensive
              properties both commercial and residential for either selling or
              renting luxury and exclusive properties in different areas such as
              Palm Jumeirah, (The World’s largest man-made Island) Burj Khalifa
              (The Highest Tower in the World), Emirates Hills, Downtown Dubai,
              Arabian Ranches, Green Community, Dubai Marina, Jumeirah Beach
              Residence, Jumeirah Golf Estate, Jumeirah Lake Towers, Emirates
              Living, Business Bay, Acacia Avenues, Al Barari, DIFC, World Trade
              Center and many more.
              <br />
              <br />
              S.A.S International Real Estate is the sound choice to sell or buy
              your property with our wide network of agents. We are ready to
              assist you on the best way to search for a new home or even for
              investment opportunity providing the most competitive prices in
              the market. We are highly customer focused and ensure that all
              sellers, buyers and developers are provided with exceptional
              personalized service, exceeded only by the highest level of
              performance and treated with respect and professionalism. Our
              reputation for discretion assures that our clients retain their
              privacy and confidentiality.
              <br />
              <br />
              We have over thousands of property units available for sale or
              investment which is listed in our 25 portals locally and globally.
              Additionally to this, we market our selected properties in Gulf
              News and Property Monthly. We aspire to be the forefront
              information provider for properties and real estate in Dubai.
            </p>
            
          </div>
        </div>
      </>
    );
  };