import { createAction } from 'deox';
import { PropertiesPayload } from '../../../server/payload/properties';
import { PropertyModel } from '../../../server/models/property';
import { PropertyPayload } from '../../../server/payload/property';


export class PropertyActions {
  static property = {
    request: createAction('property/property_request', resolve => (payload: PropertyPayload) =>
      resolve(payload),
    ),
    success: createAction('property/property_success', resolve => (payload: PropertyModel) =>
      resolve(payload),
    ),
    fail: createAction('property/property_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}