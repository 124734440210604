import { useSelector } from "react-redux";
import { RootState } from "../store/rootState";
import { RecentProperty } from "./recent_product";

export const Footer = () => {
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );

  const RecentProperties = () => {
    return (
      <>
        <div className="col-lg-4 col-md-5">
          <div className="footer-widget contact">
            <h3 className="title">Recent Properties</h3>
            <ul className="property_sec">
            {[
                  ...((properties ?? []).slice(0,2)).map((property, index) => (
                    <RecentProperty property={property} />
                  )),
                ]}
            </ul>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <footer className="footer bg-style wow fadeInUp">
        <div className="container">
          <div className="footer-upper">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="footer-widget about-widget">
                  {" "}
                  <a href="#">
                    {" "}
                    <img
                      alt=""
                      src="images/sas_logo.jpg"
                      style={{ height: 80, width: "auto" }}
                    />
                  </a>
                  <p>
                    Our vision is to benefit from the property development
                    opportunities within the region, and prosper into a well
                    recognized Real Estate Investment company within a short
                    time frame.
                  </p>
                  <div className="readmore">
                    <a href="#">Read More </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="footer-widget quick-links">
                  <h3 className="title">Quick links</h3>
                  <ul>
                    <li>
                      <a href="#."> Home</a>{" "}
                    </li>
                    <li>
                      <a href="#."> Properties</a>{" "}
                    </li>
                    <li>
                      <a href="#."> Rent</a>{" "}
                    </li>
                    <li>
                      <a href="#."> Buy</a>{" "}
                    </li>
                    <li>
                      <a href="tel:0097142504444" target="_blank"> Contact Us</a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <RecentProperties />
              <div className="col-lg-3 col-md-4">
                <div className="footer-widget contact">
                  <h3 className="title">Contact Info</h3>
                  <ul className="footer-adress">
                    <li className="footer_address">
                      {" "}
                      <i className="fas fa-map-signs"></i>{" "}
                      <span>
                        Address: Al Garhoud Building Number 30, Street 65 -
                        Dubai
                      </span>{" "}
                    </li>
                    <li className="footer_email">
                      {" "}
                      <i
                        className="fas fa-envelope"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>
                        <a href="mailto:info@sasrealestateuae.com">
                          {" "}
                          info@sasrealestateuae.com{" "}
                        </a>
                      </span>{" "}
                    </li>
                    <li className="footer_phone">
                      {" "}
                      <i className="fas fa-phone-alt"></i>{" "}
                      <span>
                        <a href="tel:0097142504444"> 00971 4 250 4444</a>
                      </span>{" "}
                    </li>
                  </ul>
                  <div className="social-icons footer_icon">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100092341275931" target="_blank">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/sas.international.realestate/" target="_blank">
                          <i
                            className="fab fa-instagram"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
