import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import TabbyBlackIconSvgImage from "../../assets/tabby-black-icon.svg";
import { Figure } from "react-bootstrap";
import { TopBar } from "../../components/topbar";
import { MenuBar } from "../../components/menubar";
import { Footer } from "../../components/footer";
import { useAction } from "../../utils/hooks/action";
import { PropertiesActions } from "../properties/module/actions";
import { About } from "../home/components/about";
import { Vision } from "../home/components/vision";
import { PropertyModel } from "../../server/models/property";
import { PropertyItem } from "../home/components/property";
import { Emirates } from "../home/components/emirates";
import { PropertiesForRent } from "../home/components/properties_for_rent";
import { Search } from "../home/components/search";

export const AboutPage = () => {
  // Actions
  const requestProperties = useAction(PropertiesActions.properties.request);
  // States
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );
  // Actions
  const navigate = useNavigate();

  useEffect(() => {
    requestProperties({});
  }, []);

  return (
    <div>
      <TopBar />
      <MenuBar />
      
      <About />
      
      <section className="perfect_home_wrap wow fadeInUp">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="perfect_text">
                <h1>Want to find your perfect home?</h1>
                <span>
                  Contact Us and explore a wide range of real estate
                  opportunities across the emirates of the UAE. Your dream
                  property is just a phone call away.
                </span>{" "}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="readmore">
                <a href="tel:0097142504444" target="_blank">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
