export const TopBar = () => {
    return <>
    <div className="topbar-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4">
              <ul className="social_media style_none">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100092341275931" target="_blank">
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="top_right">
                <div className="topbar_phone">
                  <a href="#">
                    <i className="fas fa-phone-alt" aria-hidden="true"></i>{" "}
                    (+971) 4 250 4444{" "}
                  </a>
                </div>
                <div className="topbar_login">
                  <a href="tel:0097142504444" target="_blank">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</>
}