import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  PropertiesActions,
} from './actions';
import { PropertiesTypes } from './types';

const initialState: PropertiesTypes = {
  properties: [],
  total_count: 0,
  loading: true
};

export const propertiesReducer = createReducer(initialState, handleAction => [
  handleAction(PropertiesActions.properties.request, (state, payload) =>
    produce(state, draft => {
      console.log('requesting sliders');
      draft.loading = true;
    }),
  ),
  handleAction(PropertiesActions.properties.success, (state, { payload }) =>
    produce(state, draft => {
      draft.properties = payload.properties
    }),
  ),
  handleAction(PropertiesActions.properties.fail, (state, { payload }: any) =>
    produce(state, draft => {
      //draft.loadingProducts = false;
    }),
  ),
]);
