import { all } from 'redux-saga/effects';
import { watchProperties } from '../screens/properties/module/sagas';
import { watchProperty } from '../screens/property/module/sagas';

export default function* rootSaga() {
  yield all([
    watchProperties(),
    watchProperty(),
  ]);
}
