import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import TabbyBlackIconSvgImage from "../../assets/tabby-black-icon.svg";
import { Figure } from "react-bootstrap";
import { TopBar } from "../../components/topbar";
import { MenuBar } from "../../components/menubar";
import { Footer } from "../../components/footer";
import { useAction } from "../../utils/hooks/action";
import { PropertiesActions } from "../properties/module/actions";
import { About } from "./components/about";
import { Vision } from "./components/vision";
import { PropertyModel } from "../../server/models/property";
import { PropertyItem } from "./components/property";
import { Emirates } from "./components/emirates";
import { PropertiesForRent } from "./components/properties_for_rent";
import { Search } from "./components/search";

export const HomePage = () => {
  // Actions
  const requestProperties = useAction(PropertiesActions.properties.request);
  // States
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );
  // Actions
  const navigate = useNavigate();

  useEffect(() => {
    requestProperties({});
  }, []);

  return (
    <div>
      <TopBar />
      <MenuBar />
      <Search />

      <div className="property-wrap wow fadeInUp">
        <div className="container">
          <div className="title">
            <h1>
              Featured Properties{" "}
              {/* <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </span> */}
            </h1>
          </div>

          <ul className="row">
            {[
              ...(properties ?? []).map((property, index) => (
                <PropertyItem property={property} />
              )),
            ]}
          </ul>
        </div>
      </div>
      <div className="buy-wrap wow fadeInUp">
        <div className="container">
          <div className="title">
            <h1>Buy or sell your house</h1>
          </div>
          <p>
            Ready to take the next step in your real estate journey? We're just
            a phone call or email away. Contact us today to discuss your real
            estate goals, schedule a consultation, or get answers to any
            questions you may have.
          </p>
          <div className="start_btn">
            {" "}
            <span>
              <a href="tel:0097142504444" target="_blank">Contact Us</a>
            </span>{" "}
            <span>
              <a href="#/properties">Browse Properties</a>
            </span>{" "}
          </div>
        </div>
      </div>

      <Emirates />

      <PropertiesForRent/>

      <section className="perfect_home_wrap wow fadeInUp">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="perfect_text">
                <h1>Want to find your perfect home?</h1>
                <span>
                  Contact Us and explore a wide range of real estate
                  opportunities across the emirates of the UAE. Your dream
                  property is just a phone call away.
                </span>{" "}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="readmore">
                <a href="tel:0097142504444" target="_blank">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="our_team_wrap wow fadeInUp">
        <div className="container">
          <h1>Meet Our Agents</h1>
          <span>Lorem ipsum dolor sit amet consectetur</span>
          <div className="row">
            <div className="col-lg-4">
              <div className="team_wrp">
                <div className="team_member  wow fadeInUp">
                  <div className="team_img">
                    <img alt="" src="images/team_1.png" />
                  </div>
                  <div className="team_icons">
                    <ul>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team_name">
                    <h3>David Strozier</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="team_wrp">
                <div className="team_member wow fadeInUp">
                  <div className="team_img">
                    <img alt="" src="images/team_2.png" />
                  </div>
                  <div className="team_icons">
                    <ul>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team_name">
                    <h3>James Adult</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="team_wrp">
                <div className="team_member wow fadeInUp">
                  <div className="team_img">
                    <img alt="" src="images/team_3.png" />
                  </div>
                  <div className="team_icons">
                    <ul>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team_name">
                    <h3>Jennie Wilson</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};
