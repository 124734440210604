import { useNavigate } from "react-router-dom";

export const MenuBar = () => {
  const navigate = useNavigate();

  function scrollToElement(elementId: any) {
    var element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); // 'smooth' for smooth scrolling
    }
  }

  return (
    <>
      <div className="header-wrap wow fadeInUp" style={{ background: "black" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 navbar navbar-expand-lg navbar-light">
              <div className="header_logo">
                <a href="#">
                  <img
                    alt=""
                    src="images/sas_logo.jpg"
                    style={{ height: 65, width: "auto" }}
                  />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {" "}
                <span className="navbar-toggler-icon"></span>{" "}
              </button>
            </div>
            <div className="col-lg-9">
              <nav className="navbar navbar-expand-lg navbar-light">
                {" "}
                <a className="navbar-brand" href="#">
                  Navbar
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <button
                    className="close-toggler"
                    type="button"
                    data-toggle="offcanvas"
                  >
                    {" "}
                    <span>
                      <i className="fas fa-times-circle" aria-hidden="true"></i>
                    </span>{" "}
                  </button>
                  <ul className="navbar-nav mr-auto pointer">
                    <li className="nav-item active">
                      <a
                        className="nav-link"
                        onClick={() => {
                          navigate(`/properties`);
                        }}
                      >
                        {" "}
                        Property For Sell{" "}
                        <span className="caret">
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </a>{" "}
                      <i className="fas fa-caret-down"></i>
                      <ul className="submenu">
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Apartment/Flat
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Villa/House
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Towenhouse
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Penthouse
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Residential Building
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Villa Compound
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Residential Floor
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Commercial
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Rooms
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Short Term (Monthly)
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate(`/properties`);
                            }}
                          >
                            Short Term (Daily)
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={() => {
                          scrollToElement('rent')
                        }}
                      >
                        {" "}
                        Property For Rent{" "}
                        <span className="caret">
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </a>{" "}
                      <i className="fas fa-caret-down"></i>
                      <ul className="submenu">
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Apartment/Flat</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Villa/House</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Towenhouse</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Penthouse</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Residential Building</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Villa Compound</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Residential Floor</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Commercial</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Rooms</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Short Term (Monthly)</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties`);
                          }}
                        >
                          <a>Short Term (Daily)</a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={() => {
                        scrollToElement('emirates')
                      }}>
                        {" "}
                        Emirate{" "}
                        <span className="caret">
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </a>{" "}
                      <i className="fas fa-caret-down"></i>
                      <ul className="submenu pointer">
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=dubai`);
                          }}
                        >
                          <a>Dubai</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=abu dhabi`);
                          }}
                        >
                          <a>Abu Dhabi</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=sharjah`);
                          }}
                        >
                          <a>Sharjah</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=umm al quwain`);
                          }}
                        >
                          <a>Umm Al Quwain</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=al ain`);
                          }}
                        >
                          <a>Al Ain</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=ajman`);
                          }}
                        >
                          <a>Ajman</a>
                        </li>
                        <li
                          onClick={() => {
                            navigate(`/properties?emirate=fujairah`);
                          }}
                        >
                          <a>Fujairah</a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item pointer">
                      <a className="nav-link" onClick={() => {
                        navigate('/vision')
                      }}> Our Vision</a>
                    </li>
                    <li className="nav-item pointer">
                      <a className="nav-link" onClick={() => {
                        navigate('/about')
                      }}> About Us</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
