export const Vision = () => {
    return (
      <>
        <div className="buy-wrap wow fadeInUp" style={{ background: "white" }}>
          <div className="container">
            <div className="title">
              <h1 style={{ color: "black" }}>Our vision</h1>
            </div>
            <p style={{ color: "black" }}>
              Our vision is to benefit from the property development
              opportunities within the region, and prosper into a well
              recognized Real Estate Investment company within a short time
              frame.
              <br />
              <br />
              We intend to exploit our professionalism and experience to the
              fullest in order to maximize our profits and satisfy our clients
              and business partners.
            </p>
            {/* <div className="start_btn">
              {" "}
              <span>
                <a href="tel:0097142504444" target="_blank">Contact Us</a>
              </span>{" "}
              <span>
                <a href="#/properties">Browse Properties</a>
              </span>{" "}
            </div> */}
          </div>
        </div>
      </>
    );
  };