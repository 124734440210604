import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { PropertiesActions } from './actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';


function* properties({ payload }: ActionType<typeof PropertiesActions.properties.request>): SagaIterator {
  try {
    var properties = {
      properties: [
        {
          id: 1,
          building: 'The Address The Blvd',
          emirate: 'dubai',
          address: 'Downtown Dubai, Dubai',
          headline: 'Skyline View | Vacant | High Floor | Serviced',
          description: `
          SAS Real Estate is thrilled to present this exquisite one-bedroom apartment available for rent in the heart of Dubai Downtown. 
          Property Highlights:
          
          Size: 807 SQFT
          Layout: 1 Bedroom, 2 Bathrooms
          Furnishing: Fully furnished to a high standard
          Balcony: Offering panoramic views of the Dubai Skyline and Shaikh Zayed Road
          Amenities: Fully serviced with twice-weekly cleaning, a fully fitted modern kitchen, dining and living areas
          Security: 24/7 security for your peace of mind
          Leisure: Access to a pool and gym
          Inclusions: All bills are included
          Parking: Covered parking space
          Convenience: Full-service lobby
          Access: Direct entry to Dubai Mall`,
          type: 'Hotel Apartment',
          purpose: 'rent',
          furnishing: true,
          bathrooms: 2,
          bedrooms: 1,
          sqft: 905,
          price: 220000,
          images: [
            'https://images.bayut.com/thumbnails/457963373-1066x800.webp',
            'https://images.bayut.com/thumbnails/457963375-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992251-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992252-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992253-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992254-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992256-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992257-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992258-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992259-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992260-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992261-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992262-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992263-1066x800.webp',
            'https://images.bayut.com/thumbnails/457992264-1066x800.webp',
            'https://images.bayut.com/thumbnails/297730311-1066x800.webp',
            'https://images.bayut.com/thumbnails/251669307-1066x800.webp'
          ],
          amenities: [
            {
              type: 'FEATURES',
              values: [
                'Furnished',
                'Electricity Backup',
                'Parking Spaces: 1',
                'Centrally Air-Conditioned',
                'Double Glazed Windows',
              ]
            },
            {
              type: 'BUILDING',
              values: [
                'Balcony or Terrace',
                'Lobby in Building',
                'Elevators in Building: 6',
                'Service Elevators',
                'Prayer Room',
                'Reception/Waiting Room'
              ]
            },
            {
              type: 'HEALTH AND FITNESS',
              values: [
                'First Aid Medical Center',
                'Gym or Health Club',
                'Swimming Pool',
                'Jacuzzi',
                'Sauna',
                'Steam Room'
              ]
            },
            {
              type: 'RECREATION AND FAMILY',
              values: [
                'Day Care Center',
                'Kids Play Area',
                'Lawn or Garden',
                'Barbeque Area',
                'Cafeteria or Canteen'
              ]
            },
            {
              type: 'CLEANING AND MAINTENANCE',
              values: [
                'Waste Disposal',
                'Maintenance Staff',
                'Cleaning Services'
              ]
            },
            {
              type: 'BUSINESS AND SECURITY',
              values: [
                'Business Center',
                'Conference Room',
                'Security Staff',
                'CCTV Security'

              ]
            },
            {
              type: 'LAUNDRY AND KITCHEN',
              values: [
                'Laundry Room',
                'Laundry Facility'

              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'Broadband Internet',
                'Satellite/Cable TV',
                'Intercom'
              ]
            },
            {
              type: 'MISCELLANEOUS',
              values: [
                'ATM Facility',
                '24 Hours Concierge',
                'View',
                'Freehold'
              ]
            }
          ]
        },
        {
          id: 2,
          building: 'Mira Oasis 1',
          emirate: 'dubai',
          address: 'Mira Oasis, Reem, Dubai',
          headline: 'Type H | With Study | Best Location | Corner',
          description: `
          SAS International Real Estate is excited to present this enchanting villa, categorized as a Type H cluster, ideally positioned in close proximity to a delightful children's park. This exceptional property features a spacious garden and a balcony, making it an ideal choice for families seeking a cozy and welcoming living space. 
          Key Property Details:
          Configuration: 3 bedrooms + 4 bathrooms + Study room
          Furnishing: Unfurnished
          Plot size measuring 3074 square feet
          Villa Type: Type H
          Additional Spaces: Study room, Maid room with attached bathroom, and 2 Storage rooms
          Amenities: Access to a children's play area
          Outdoor Feature: Expansive garden
          Discover the perfect blend of comfort and charm for family living in this exceptional villa.
          `,
          type: 'Villa',
          purpose: 'sell',
          furnishing: false,
          bedrooms: 3,
          bathrooms: 4,
          sqft: 3074,
          price: 2650000,
          images: [
            'https://images.bayut.com/thumbnails/473477282-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477283-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477284-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477285-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477286-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477287-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477288-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477289-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477290-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477291-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477292-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477293-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477294-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477295-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477296-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477297-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477298-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477299-1066x800.webp',
            'https://images.bayut.com/thumbnails/473477300-1066x800.webp'
          ],
          amenities: [
            {
              type: 'FEATURES',
              values: [
                'Parking Spaces: 2',
                'Centrally Air-Conditioned',
                'Double Glazed Windows',
                'Storage Areas',
                'Study Room'
              ]
            },
            {
              type: 'BUILDING',
              values: [
                'Balcony or Terrace',
                'Total Floors: 1'
              ]
            },
            {
              type: 'HEALTH AND FITNESS',
              values: [
                'First Aid Medical Center',
                'Gym or Health Club',
                'Swimming Pool',
                'Sauna'

              ]
            },
            {
              type: 'RECREATION AND FAMILY',
              values: [
                'Kids Play Area',
                'Lawn or Garden',
                'Barbeque Area'


              ]
            },
            {
              type: 'CLEANING AND MAINTENANCE',
              values: [
                'Maintenance Staff',
                'Cleaning Services'

              ]
            },
            {
              type: 'BUSINESS AND SECURITY',
              values: [
                'Business Center',
                'Security Staff',
                'CCTV Security'


              ]
            },
            {
              type: 'LAUNDRY AND KITCHEN',
              values: [
                'Shared Kitchen',
                'Laundry Room'



              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'Broadband Internet',
                'Satellite/Cable TV',

              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'ATM Facility',
                'Maids Room',
                'Freehold'
              ]
            },
          ]
        },
        {
          id: 3,
          building: 'Jebel Ali Hills',
          address: 'Jebel Ali, Dubai',
          emirate: 'dubai',
          headline: 'Huge | Four Plots | Corner | Three Roads facing | Near to Park',
          description: `
          SAS International REAL ESTATE would love to offer 4 Plots in Jebel Ali Hills,  a residential off-plan land development project by Meraas. Offering among the most affordable freehold plots in the market today. 
          Features:
          Four Plots (Square) facing Three roads. 
          Residential Villas
          Permitted Height G+1
          Ownership: Freehold
          `,
          type: 'Residential Plot',
          purpose: 'sell',
          furnishing: false,
          bedrooms: undefined,
          bathrooms: undefined,
          sqft: 56730,
          price: 17019000,
          images: [
            'https://images.bayut.com/thumbnails/418409273-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409274-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409275-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409276-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409277-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409278-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409279-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409280-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409281-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409282-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409283-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409284-1066x800.webp',
            'https://images.bayut.com/thumbnails/418409285-1066x800.webp',
          ],
          amenities: [
            {
              type: 'RECREATION AND FAMILY',
              values: [
                'Kids Play Area',
                'Lawn or Garden',
                'Barbeque Area'

              ]
            },
            {
              type: 'CLEANING AND MAINTENANCE',
              values: [
                'Waste Disposal',
                'Cleaning Services'


              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'Broadband Internet',
                'Satellite/Cable TV'
              ]
            },
            {
              type: 'MISCELLANEOUS',
              values: [
                '24 Hours Concierge',
                'Freehold'
              ]
            },
          ]
        },
        {
          id: 4,
          building: 'Boulevard Point',
          address: 'Downtown Dubai, Dubai',
          emirate: 'dubai',
          headline: 'Higher Floor| Burj & Fountain View| Spacious and Bright Layout',
          description: `
          SAS International Real Estate offer you this spacious two-bedroom- Burj and Fountain View apartment located in one of the most popular towers in Dubai Downtown, the Boulevard Point. 
          Key Features:
          * Burj Khalifa and Dancing Fountain Views
          * 2 Bedrooms with 3 Bathrooms
          * BUA Size: 1,414.19 Sq. Ft
          * Balcony
          * Large Layout
          * Spacious Living and Dining Area
          * Fully Fitted Kitchen
          * Built-in Wardrobes
          * Access Swimming Pool
          * Well Equipped GYM
          * Parking
          * Security & CCTV 24/7
          
          This 63-story luxurious building is ideally situated in the heart of the city and only walking distance away from the iconic Burj Khalifa Tower, Dubai Opera and direct connection to ever vibrant Dubai Mall and Dancing Fountains. 
          Tower facilities include a large infinity pool, a kid's pool, and a gymnasium. There are retail outlets and restaurants within the tower and it is right along the famous Boulevard, which offers a plethora of award-winning dining options. The tower is also within walking distance to Dubai Mall and the Opera District. 
          `,
          type: 'Apartment',
          purpose: 'sell',
          furnishing: false,
          bedrooms: 2,
          bathrooms: 3,
          sqft: 1414,
          price: 3849000,
          images: [
            'https://images.bayut.com/thumbnails/406229059-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229063-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229066-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229068-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229072-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229075-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229077-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229079-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229081-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229083-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229085-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229087-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229093-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229095-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229097-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229099-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229102-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229105-1066x800.webp',
            'https://images.bayut.com/thumbnails/406229107-1066x800.webp'
          ],
          amenities: [
            {
              type: 'FEATURES',
              values: [
                'Electricity Backup',
                'Parking Spaces: 1',
                'Centrally Air-Conditioned',
                'Central Heating',
                'Double Glazed Windows'
              ]
            },
            {
              type: 'BUILDING',
              values: [
                'Balcony or Terrace',
                'Lobby in Building',
                'Service Elevators',
                'Prayer Room',
                'Reception/Waiting Room'
              ]
            },
            {
              type: 'HEALTH AND FITNESS',
              values: [
                'First Aid Medical Center',
                'Gym or Health Club',
                'Swimming Pool',
                'Jacuzzi',
                'Sauna',
                'Steam Room'

              ]
            },
            {
              type: 'RECREATION AND FAMILY',
              values: [
                'Day Care Center',
                'Kids Play Area',
                'Lawn or Garden',
                'Barbeque Area',
                'Cafeteria or Canteen'


              ]
            },
            {
              type: 'CLEANING AND MAINTENANCE',
              values: [
                'Waste Disposal',
                'Maintenance Staff',
                'Cleaning Services'



              ]
            },
            {
              type: 'BUSINESS AND SECURITY',
              values: [
                'Business Center',
                'Conference Room',
                'Security Staff',
                'CCTV Security'




              ],
            },
            {
              type: 'LAUNDRY AND KITCHEN',
              values: [
                'Laundry Room',
                'Laundry Facility'
              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'Broadband Internet',
                'Satellite/Cable TV',
                'Intercom'
              ]
            },
            {
              type: 'MISCELLANEOUS',
              values: [
                'ATM Facility',
                '24 Hours Concierge',
                'View'
              ]
            },
          ]
        },
        {
          id: 5,
          building: 'The Address Fountain Views 1',
          emirate: 'dubai',
          address: 'The Address Residence Fountain Views, Downtown Dubai, Dubai',
          headline: 'Higher Floor| Burj & Fountain View| Spacious and Bright Layout',
          description: `
          SAS International offer you this luxurious 3-Bedroom apartment for sale in The Address Fountain Views 1, Downtown. 
          - 3 Bedroom Hotel Apartment
          - Fully furnished and fully serviced
          - Size: 2,218.12 SQ. FT
          - Modern layout kitchen with all appliances
          - 3 En-suite bathrooms and powder room
          - Burj Khalifa and Fountain view
          - 2X Housekeeping per week
          - 2 Allocated parking
          - A pool bar
          - State-of-the-art fitness center and spa
          - Fully equipped health club & rooftop recreation deck
          - Service charge: AED 75,000/year
          - Selling price: AED 11.0 Million( Negotiable)
          SAS International is a leading property brokerage and investment company. Our property experts have the highest standards, systems, and performance necessary to fulfill all investor’s needs to achieve a safe, secured, guaranteed and highest return on investment with a rock-solid investment strategy. 
          `,
          type: 'Apartment',
          purpose: 'sell',
          furnishing: true,
          bedrooms: 3,
          bathrooms: 4,
          sqft: 2218,
          price: 12000000,
          images: [
            'https://images.bayut.com/thumbnails/379577957-1066x800.webp',
            'https://images.bayut.com/thumbnails/384591827-1066x800.webp',
            'https://images.bayut.com/thumbnails/367972948-1066x800.webp',
            'https://images.bayut.com/thumbnails/367972949-1066x800.webp',
            'https://images.bayut.com/thumbnails/386418619-1066x800.webp',
            'https://images.bayut.com/thumbnails/470086051-1066x800.webp',
            'https://images.bayut.com/thumbnails/470086052-1066x800.webp',
            'https://images.bayut.com/thumbnails/470086052-1066x800.webp',
            'https://images.bayut.com/thumbnails/367972953-1066x800.webp',
            'https://images.bayut.com/thumbnails/382586181-1066x800.webp',
            'https://images.bayut.com/thumbnails/367972955-1066x800.webp',
            'https://images.bayut.com/thumbnails/367972956-1066x800.webp',
            'https://images.bayut.com/thumbnails/367973034-1066x800.webp'
          ],
          amenities: [
            {
              type: 'Features',
              values: [
                'Furnished',
                'Electricity Backup',
                'Parking Spaces: 2',
                'Centrally Air-Conditioned',
                'Central Heating',
                'Double Glazed Windows',
              ]
            },
            {
              type: 'BUILDING',
              values: [
                'Balcony or Terrace',
                'Lobby in Building',
                'Elevators in Building: 6',
                'Service Elevators',
                'Completion Year: 2019',
                'Prayer Room',
                'Reception/Waiting Room',
                'Flooring',
              ]
            },
            {
              type: 'HEALTH AND FITNESS',
              values: [
                'First Aid Medical Center',
                'Gym or Health Club',
                'Swimming Pool',
                'Jacuzzi',
                'Sauna',
                'Steam Room'
              ]
            },
            {
              type: 'RECREATION AND FAMILY',
              values: [
                'Day Care Center',
                'Kids Play Area',
                'Lawn or Garden',
                'Barbeque Area',
                'Cafeteria or Canteen'
              ]
            },
            {
              type: 'CLEANING AND MAINTENANCE',
              values: [
                'Waste Disposal',
                'Maintenance Staff',
                'Cleaning Services'

              ]
            },
            {
              type: 'BUSINESS AND SECURITY',
              values: [
                'Conference Room',
                'Security Staff',
                'CCTV Security'
              ]
            },
            {
              type: 'LAUNDRY AND KITCHEN',
              values: [
                'Shared Kitchen',
                'Laundry Room',
                'Laundry Facilit'
              ]
            },
            {
              type: 'TECHNOLOGY',
              values: [
                'Broadband Internet',
                'Satellite/Cable TV',
                'Intercom'
              ]
            },
            {
              type: 'MISCELLANEOUS',
              values: [
                'Maids Room',
                'View',
                'Freehold'
              ]
            },
          ]
        }
      ],
      total: 2
    }

    if(payload.emirate) {
      properties.properties = properties.properties.filter(property => property.emirate.toLowerCase().includes(payload.emirate!!))
    }
    yield* put(PropertiesActions.properties.success(
      properties as any
    )) as any;
  } catch (error) {

  }
}

export function* watchProperties(): SagaIterator {
  yield* takeLatest(PropertiesActions.properties.request, properties) as any;
}
