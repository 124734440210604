import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { PropertyActions } from './actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';


function* property({ payload }: ActionType<typeof PropertyActions.property.request>): SagaIterator {
  try {
  
  } catch (error) {
  
  }
}

export function* watchProperty(): SagaIterator {
  yield* takeLatest(PropertyActions.property.request, property) as any;
}
