import { useNavigate } from "react-router-dom";
import { PropertyModel } from "../server/models/property";

export const RecentProperty = (props: { property: PropertyModel }) => {
  // Actions
  const navigate = useNavigate();
  return (
    <>
      <li
        onClick={() => {
          navigate(`/properties/${props.property.id}`);
        }}
      >
        <div className="rec_proprty">
          <div className="propertyImg">
            <img
              style={{ width: 90, height: 90 }}
              alt=""
              src={props.property.images[0] ?? ""}
            />
          </div>
          <div className="property_info">
            <h4>
              <a href="#">{props.property.building}</a>
            </h4>
            <p>{props.property.address}</p>
            <div className="priceWrp">
              AED {props.property.price.toLocaleString()}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};
