import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { PropertyItem } from "./property";

export const PropertiesForRent = () => {
    const { loading, properties } = useSelector(
        (state: RootState) => state.properties
      );
      
    return <>
    <div id="rent" className="property-wrap property_rent wow fadeInUp">
        <div className="container">
          <div className="title">
            <h1>
              Properties For Rent{" "}
              {/* <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </span> */}
            </h1>
          </div>

          <ul className="row">
            {[
              ...(properties ?? [])
                .filter((property) => property.purpose === "rent")
                .map((property, index) => <PropertyItem property={property} />),
            ]}
          </ul>
        </div>
      </div>
      </>
}