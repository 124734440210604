import { createAction } from 'deox';
import { PropertiesPayload } from '../../../server/payload/properties';
import { PropertyModel } from '../../../server/models/property';


export class PropertiesActions {
  static properties = {
    request: createAction('properties/properties_request', resolve => (payload: PropertiesPayload) =>
      resolve(payload),
    ),
    success: createAction('properties/properties_success', resolve => (payload: { properties: PropertyModel[], total: number }) =>
      resolve(payload),
    ),
    fail: createAction('properties/properties_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}