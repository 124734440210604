import { useSelector } from "react-redux";
import { Footer } from "../../components/footer";
import { MenuBar } from "../../components/menubar";
import { TopBar } from "../../components/topbar";
import { RootState } from "../../store/rootState";
import { useAction } from "../../utils/hooks/action";
import { PropertiesActions } from "./module/actions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { PropertyItem } from "./components/property";
import { Categories } from "./components/categories";
import { Pagination } from "react-bootstrap";
import { RecentProperties } from "./components/recents_properties";

export const PropertiesPage = () => {
  // Params
  let [searchParams, setSearchParams] = useSearchParams();
  // Actions
  const requestProperties = useAction(PropertiesActions.properties.request);
  // States
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );
  // Actions
  const navigate = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    var emirate = searchParams.get('emirate')
    if(emirate) {
      requestProperties({emirate: emirate});
    } else {
      requestProperties({});
    }
  }, []);

  return (
    <div>
      <TopBar />
      <MenuBar />
      <div className="innerHeading">
        <div className="container">
          <h1>Properties</h1>
        </div>
      </div>

      <div className="innercontent">
        <div className="container">
          <div className="row listing_wrap">
            <div className="col-lg-4">
              <form>
                <div className="sidebar_form card card-body  wow fadeInUp">
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Keyword"
                    />
                    <div className="hover_icon">
                      <a href="#">
                        <i className="fas fa-search"></i>
                      </a>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Location"
                    />
                    <div className="hover_icon">
                      <a href="#">
                        <i className="fas fa-map-marker-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Status">
                        Property Status
                      </option>
                      <option>For Sale</option>
                      <option>For Rent</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      {" "}
                      <span className="current">Property Status</span>
                      <ul className="list">
                        <li
                          data-value="Property Status"
                          data-display="Property Status"
                          className="option selected focus"
                        >
                          Property Status
                        </li>
                        <li data-value="For Sale" className="option">
                          For Sale
                        </li>
                        <li data-value="For Rent" className="option">
                          For Rent
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Type">
                        Property Type
                      </option>
                      <option>Residential</option>
                      <option>Commercial</option>
                      <option>Land</option>
                      <option>Luxury</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Property Type</span>
                      <ul className="list">
                        <li
                          data-value="Property Type"
                          data-display="Property Type"
                          className="option selected focus"
                        >
                          Property Type
                        </li>
                        <li data-value="Residential" className="option">
                          Residential
                        </li>
                        <li data-value="Commercial" className="option">
                          Commercial
                        </li>
                        <li data-value="Land" className="option">
                          Land
                        </li>
                        <li data-value="Luxury" className="option">
                          Luxury
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Type">Price</option>
                      <option>$999 - $1999</option>
                      <option>$1999 - $2999</option>
                      <option>$2999 - $3999</option>
                      <option>$3999 - $4999</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Price</span>
                      <ul className="list">
                        <li
                          data-value="Property Type"
                          data-display="Property Type"
                          className="option selected focus"
                        >
                          Property Price
                        </li>
                        <li data-value="Residential" className="option">
                          $999 - $1999
                        </li>
                        <li data-value="Commercial" className="option">
                          $1999 - $2999
                        </li>
                        <li data-value="Land" className="option">
                          $2999 - $3999
                        </li>
                        <li data-value="Luxury" className="option">
                          $3999 - $4999
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Bedrooms">Bedrooms</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Bedrooms</span>
                      <ul className="list">
                        <li
                          data-value="Bedrooms"
                          data-display="Bedrooms"
                          className="option selected focus"
                        >
                          Bedrooms
                        </li>
                        <li data-value="1" className="option">
                          1
                        </li>
                        <li data-value="2" className="option">
                          2
                        </li>
                        <li data-value="3" className="option">
                          3
                        </li>
                        <li data-value="4" className="option">
                          4
                        </li>
                        <li data-value="5" className="option">
                          5
                        </li>
                        <li data-value="6" className="option">
                          6
                        </li>
                        <li data-value="7" className="option">
                          7
                        </li>
                        <li data-value="8" className="option">
                          8
                        </li>
                        <li data-value="9" className="option">
                          9
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Bathrooms">Bathrooms</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Bathrooms</span>
                      <ul className="list">
                        <li
                          data-value="Bathrooms"
                          data-display="Bathrooms"
                          className="option selected"
                        >
                          Bathrooms
                        </li>
                        <li data-value="1" className="option">
                          1
                        </li>
                        <li data-value="2" className="option">
                          2
                        </li>
                        <li data-value="3" className="option">
                          3
                        </li>
                        <li data-value="4" className="option">
                          4
                        </li>
                        <li data-value="5" className="option">
                          5
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Min Area"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Max Area"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="advanceWrp faqs">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            {" "}
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              className="collapsed"
                              href="#collapse1"
                            >
                              Advanced Features
                            </a>{" "}
                          </h4>
                        </div>
                        <div id="collapse1" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic1"
                              />
                              <label></label>
                              Air Conditioning{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic2"
                              />
                              <label></label>
                              WiFi{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic3"
                              />
                              <label></label>
                              Dryer{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic4"
                              />
                              <label></label>
                              Microwave{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic5"
                              />
                              <label></label>
                              Gym{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic6"
                              />
                              <label></label>
                              TV Cable{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic7"
                              />
                              <label></label>
                              Swimming Pool{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic8"
                              />
                              <label></label>
                              Outdoor Shower{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic9"
                              />
                              <label></label>
                              Refrigerator{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic10"
                              />
                              <label></label>
                              Window Coverings{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="submit" className="submit" value="Search" />
                  </div>
                </div>
              </form>
              <Categories/>
              <RecentProperties/>
            </div>
            <div className="col-lg-8">
              <ul className="nav sortWrp  wow fadeInUp" role="tablist">
                <li className="list-inline-item mr-auto">
                  {" "}
                  <span className="title-text">Sort by</span>
                  <div className="btn-group">
                    {" "}
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      Based Properties{" "}
                    </a>
                    <div className="dropdown-menu" x-placement="top-start">
                      {" "}
                      <a className="dropdown-item" href="#">
                        Low to High Price
                      </a>{" "}
                      <a className="dropdown-item" href="#">
                        High to Low Price{" "}
                      </a>{" "}
                      <a className="dropdown-item" href="#">
                        Sell Properties
                      </a>{" "}
                      <a className="dropdown-item" href="#">
                        Rent Properties
                      </a>{" "}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link active" href="property_grid.html">
                    {" "}
                    <span className="fa fa-th-large"></span>
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="property_list.html">
                    {" "}
                    <span className="fa fa-th-list"></span>{" "}
                  </a>{" "}
                </li>
              </ul>

              <ul className="row">
                {[
                  ...(properties ?? []).map((property, index) => (
                    <PropertyItem property={property} />
                  )),
                ]}
              </ul>

              <Pagination/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
