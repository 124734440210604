import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../components/footer";
import { MenuBar } from "../../components/menubar";
import { TopBar } from "../../components/topbar";
import { RootState } from "../../store/rootState";
import { useAction } from "../../utils/hooks/action";
import { PropertiesActions } from "../properties/module/actions";
import { PropertyActions } from "./module/actions";
import { SliderImage } from "./components/slider_image";
import { RecentProperties } from "../properties/components/recents_properties";

export const PropertyPage = () => {
  let { id } = useParams();
  // Actions
  const requestProperties = useAction(PropertiesActions.properties.request);
  const setProperty = useAction(PropertyActions.property.success);
  // States
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );
  const { property } = useSelector((state: RootState) => state.property);
  // Actions
  const navigate = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    requestProperties({});
  }, []);

  useEffect(() => {
    console.log("id: ${id}");
    if (properties && id) {
      const result = properties.find(
        (property) => property.id.toString() == id?.toString()
      );
      if (result) {
        setProperty(result);
      }
    }
  }, [properties, id]);

  if (!property || !id) {
    return <>NOT FOUND</>;
  }

  return (
    <div>
      <TopBar />

      <MenuBar />

      <div className="innerHeading">
        <div className="container">
          <h1>
            {property.building},{property.address}
          </h1>
        </div>
      </div>

      <div className="innercontent">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <form>
                <div
                  className="sidebar_form card card-body  wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Keyword"
                    />
                    <div className="hover_icon">
                      <a href="#">
                        <i className="fas fa-search"></i>
                      </a>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Location"
                    />
                    <div className="hover_icon">
                      <a href="#">
                        <i className="fas fa-map-marker-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Status">
                        Property Status
                      </option>
                      <option>For Sale</option>
                      <option>For Rent</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      {" "}
                      <span className="current">Property Status</span>
                      <ul className="list">
                        <li
                          data-value="Property Status"
                          data-display="Property Status"
                          className="option selected focus"
                        >
                          Property Status
                        </li>
                        <li data-value="For Sale" className="option">
                          For Sale
                        </li>
                        <li data-value="For Rent" className="option">
                          For Rent
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Type">
                        Property Type
                      </option>
                      <option>Residential</option>
                      <option>Commercial</option>
                      <option>Land</option>
                      <option>Luxury</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Property Type</span>
                      <ul className="list">
                        <li
                          data-value="Property Type"
                          data-display="Property Type"
                          className="option selected focus"
                        >
                          Property Type
                        </li>
                        <li data-value="Residential" className="option">
                          Residential
                        </li>
                        <li data-value="Commercial" className="option">
                          Commercial
                        </li>
                        <li data-value="Land" className="option">
                          Land
                        </li>
                        <li data-value="Luxury" className="option">
                          Luxury
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Property Type">Price</option>
                      <option>AED999 - AED1999</option>
                      <option>AED1999 - AED2999</option>
                      <option>AED2999 - AED3999</option>
                      <option>AED3999 - AED4999</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Price</span>
                      <ul className="list">
                        <li
                          data-value="Property Type"
                          data-display="Property Type"
                          className="option selected focus"
                        >
                          Property Price
                        </li>
                        <li data-value="Residential" className="option">
                          AED999 - AED1999
                        </li>
                        <li data-value="Commercial" className="option">
                          AED1999 - AED2999
                        </li>
                        <li data-value="Land" className="option">
                          AED2999 - AED3999
                        </li>
                        <li data-value="Luxury" className="option">
                          AED3999 - AED4999
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Bedrooms">Bedrooms</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Bedrooms</span>
                      <ul className="list">
                        <li
                          data-value="Bedrooms"
                          data-display="Bedrooms"
                          className="option selected focus"
                        >
                          Bedrooms
                        </li>
                        <li data-value="1" className="option">
                          1
                        </li>
                        <li data-value="2" className="option">
                          2
                        </li>
                        <li data-value="3" className="option">
                          3
                        </li>
                        <li data-value="4" className="option">
                          4
                        </li>
                        <li data-value="5" className="option">
                          5
                        </li>
                        <li data-value="6" className="option">
                          6
                        </li>
                        <li data-value="7" className="option">
                          7
                        </li>
                        <li data-value="8" className="option">
                          8
                        </li>
                        <li data-value="9" className="option">
                          9
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      className="wide select_option"
                      style={{ display: "none" }}
                    >
                      <option data-display="Bathrooms">Bathrooms</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                    <div
                      className="nice-select wide select_option"
                      tabIndex={0}
                    >
                      <span className="current">Bathrooms</span>
                      <ul className="list">
                        <li
                          data-value="Bathrooms"
                          data-display="Bathrooms"
                          className="option selected"
                        >
                          Bathrooms
                        </li>
                        <li data-value="1" className="option">
                          1
                        </li>
                        <li data-value="2" className="option">
                          2
                        </li>
                        <li data-value="3" className="option">
                          3
                        </li>
                        <li data-value="4" className="option">
                          4
                        </li>
                        <li data-value="5" className="option">
                          5
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Min Area"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Max Area"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="advanceWrp faqs">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            {" "}
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              className="collapsed"
                              href="#collapse1"
                            >
                              Advanced Features
                            </a>{" "}
                          </h4>
                        </div>
                        <div id="collapse1" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic1"
                              />
                              <label></label>
                              Air Conditioning{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic2"
                              />
                              <label></label>
                              WiFi{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic3"
                              />
                              <label></label>
                              Dryer{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic4"
                              />
                              <label></label>
                              Microwave{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic5"
                              />
                              <label></label>
                              Gym{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic6"
                              />
                              <label></label>
                              TV Cable{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic7"
                              />
                              <label></label>
                              Swimming Pool{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic8"
                              />
                              <label></label>
                              Outdoor Shower{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic9"
                              />
                              <label></label>
                              Refrigerator{" "}
                            </div>
                            <div className="input-group checkbox">
                              <input
                                type="checkbox"
                                name="checkname"
                                id="3dgraphic10"
                              />
                              <label></label>
                              Window Coverings{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="submit" className="submit" value="Search" />
                  </div>
                </div>
              </form>
              <div className="single-widgets widget_category mt30 fadeInUp wow">
                <h4>Categories</h4>
                <ul>
                  <li>
                    <a href="#">
                      Apartement <span>09</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Villa <span>12</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Family House <span>19</span>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#">
                      Modern Villa <span>17</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Town House <span>10</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Office <span>10</span>
                    </a>
                  </li>
                </ul>
              </div>
              <RecentProperties/>
            </div>
            <div className="col-lg-8">
              <div id="slider" className="flexslider wow fadeInUp">
                <ul className="slides">
                  <li>
                    {" "}
                    <img className="center-crop" style={{height: 400, width: '100%'}} alt="" src={property.images[0]} />{" "}
                  </li>
                  {/* <li>
                    {" "}
                    <img alt="" src="images/slide2.jpg" />{" "}
                  </li>
                  <li>
                    {" "}
                    <img alt="" src="images/slide3.jpg" />{" "}
                  </li>
                  <li>
                    {" "}
                    <img alt="" src="images/slide4.jpg" />{" "}
                  </li>
                  <li>
                    {" "}
                    <img alt="" src="images/slide5.jpg" />{" "}
                  </li>
                  <li>
                    {" "}
                    <img alt="" src="images/slide6.jpg" />{" "}
                  </li>
                  <li>
                    {" "}
                    <img alt="" src="images/slide7.jpg" />{" "}
                  </li> */}
                </ul>
              </div>

              <div className="">
                <ul style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  {[
                    ...(property.images ?? []).map((image, index) => (
                      <SliderImage image={image} />
                    )),
                  ]}
                </ul>
              </div>

              {/* Property Details */}
              <div className="property_details">
                <div className="row property_head wow fadeInUp">
                  <div className="col-lg-8 col-md-8">
                    <h3>{property.building}</h3>
                    <div className="property_address">{property.address}</div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="heart_info">
                      <div className="heart_icon">
                        <a href="#">
                          <i className="fa fa-heart" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div className="heart_icon exchange_icon">
                        <a href="#">
                          <i className="fas fa-exchange-alt"></i>
                        </a>
                      </div>
                      <div className="property_price">
                        AED {property.price.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="property_widget wow fadeInUp">
                  <h3 className="desc_head">Description</h3>
                  <p>{property.description}</p>
                </div>
                <div className="property_widget wow fadeInUp">
                  <h3 className="desc_head">Property Details</h3>
                  <div className="row prop_del">
                    <div className="col-lg-6">
                      <ul className="property_list">
                        <li>
                          <span>Property ID :</span> {property.id}
                        </li>
                        <li>
                          <span>Price :</span> AED{" "}
                          {property.price.toLocaleString()}
                        </li>
                        <li>
                          <span>Property Size :</span> {property.sqft} Sq Ft
                        </li>
                        { 
                        property.bathrooms && <li>
                          <span>Bathrooms :</span> {property.bathrooms}
                        </li>
                        }
                        { property.bedrooms && 
                          <li>
                          <span>Bedrooms :</span> {property.bedrooms ?? ''}
                        </li>
                        }
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul className="property_list">
                        <li>
                          <span>Emirate :</span> {property.emirate}
                        </li>
                        {/* <li>
                          <span>Garage :</span> 1
                        </li>
                        <li>
                          <span>Garage Size :</span> 150 SqFt
                        </li> */}
                        <li>
                          <span>Property Type :</span> {property.type}
                        </li>
                        <li>
                          <span>Property Status :</span> {(property.purpose == 'sell') ? 'For Sell' : 'For Rent'}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="property_widget wow fadeInUp">
                  <h3 className="desc_head">Amenities</h3>
                  {[
                    ...(property.amenities ?? []).map((amenity, index) => (
                      <div>
                        <h6 className="">{amenity.type}</h6>
                        <ul className="list-unstyled icon-checkbox">
                          {[
                            ...(amenity.values ?? []).map((value, index) => (
                              <li>{value}</li>
                            )),
                          ]}
                        </ul>
                        { (index < property.amenities.length -1) && <div className="divider mb-4 mt-4"></div>}
                      </div>
                    )),
                  ]}
                </div>
                {/* <div className="property_widget wow fadeInUp mt-5">
                  <h3 className="desc_head">Floor PLans</h3>
                  <div className="floor_plans faqs">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            {" "}
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              className="collapsed"
                              href="#collapse5"
                            >
                              Ground Floor 497 sq ft
                            </a>{" "}
                          </h4>
                        </div>
                        <div id="collapse5" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="floorImg">
                              <img alt="" src="images/ground_floor.jpg" />
                            </div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum tempus ullamcorper libero tincidunt
                            fermentum. Phasellus lobortis felis ac molestie
                            dictum. Morbi at efficitur mauris. Aliquam lobortis
                            massa non metus vehicula, at lacinia orci suscipit.
                            Nulla porta urna id turpis aliquet elementum.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            {" "}
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              className="collapsed"
                              href="#collapse2"
                            >
                              {" "}
                              First Floor 250 sq ft
                            </a>{" "}
                          </h4>
                        </div>
                        <div id="collapse2" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="floorImg">
                              <img alt="" src="images/first_floor.jpg" />
                            </div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum tempus ullamcorper libero tincidunt
                            fermentum. Phasellus lobortis felis ac molestie
                            dictum. Morbi at efficitur mauris. Aliquam lobortis
                            massa non metus vehicula, at lacinia orci suscipit.
                            Nulla porta urna id turpis aliquet elementum.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            {" "}
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              className="collapsed"
                              href="#collapse3"
                            >
                              {" "}
                              Garage 120 sq ft{" "}
                            </a>{" "}
                          </h4>
                        </div>
                        <div id="collapse3" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="floorImg">
                              <img alt="" src="images/garage_plan.jpg" />
                            </div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum tempus ullamcorper libero tincidunt
                            fermentum. Phasellus lobortis felis ac molestie
                            dictum. Morbi at efficitur mauris. Aliquam lobortis
                            massa non metus vehicula, at lacinia orci suscipit.
                            Nulla porta urna id turpis aliquet elementum.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
