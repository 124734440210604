import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './screens/home';
import { PropertyPage } from './screens/property';
import { PropertiesPage } from './screens/properties';
import { AboutPage } from './screens/about';
import { VisionPage } from './screens/vision';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter basename="/">
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/properties" element={<PropertiesPage/>} />
            <Route path="/properties/:id" element={<PropertyPage/>} />
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/vision" element={<VisionPage/>} />
          </Routes>
        </HashRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
