import { useNavigate } from "react-router-dom";
import { PropertyModel } from "../../../server/models/property";

export const PropertyItem = (props: { property: PropertyModel }) => {
    const navigate = useNavigate();
    
    return (
      <>
        <li
          style={{ cursor: "pointer" }}
          className="col-lg-6"
          onClick={() => {
            navigate(`/properties/${props.property.id}`);
          }}
        >
          <div className="property_box wow fadeInUp">
            <div className="propertyImg">
              <img style={{height:300}} alt="" src={props.property.images[0]} />
            </div>
            <h3>
              <a href="#">{props.property.building}</a>
            </h3>
            <div className="property_location">
              <i className="fas fa-map-marker-alt" aria-hidden="true"></i>{" "}
              {props.property.address}
            </div>
            <div className="heart_info">
              <div className="heart_icon">
                <a href="#">
                  <i className="fas fa-heart" aria-hidden="true"></i>
                </a>
              </div>
              <div className="heart_icon exchange_icon">
                <a href="#">
                  <i className="fas fa-exchange-alt"></i>
                </a>
              </div>
              <div className="property_price">AED {props.property.price.toLocaleString()}</div>
            </div>
            <div className="propert_info">
              <ul className="row">
                <li className="col-4">
                  <div className="proprty_icon">
                    <img alt="" src="images/bedroom_icon.png" />
                  </div>
                  <h5>Bedrooms {props.property.bedrooms}</h5>
                </li>
                <li className="col-4">
                  <div className="proprty_icon">
                    <img alt="" src="images/bathroom_icon.png" />
                  </div>
                  <h5>Bathrooms {props.property.bathrooms}</h5>
                </li>
                <li className="col-4">
                  <div className="proprty_icon">
                    <img alt="" src="images/garage_icon.png" />
                  </div>
                  <h5>Sqft {props.property.sqft.toLocaleString()}</h5>
                </li>
              </ul>
            </div>
            <div className="rent_info">
              <div className="apart">{props.property.type}</div>
              <div className="sale">{props.property.purpose == 'rent' ? 'For Rent' : 'For Sell'}</div>
            </div>
          </div>
        </li>
      </>
    );
  };