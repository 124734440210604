import { useNavigate } from "react-router-dom";

export const Emirates = () => {
  const navigate = useNavigate();

  return (
    <>
      <section id="emirates" className="popular_wrap wow fadeInUp">
        <div className="container">
          <h1>EMIRATES</h1>
          <span>
            Discover your dream property in the emirate of your choice, Whether
            you're looking for a luxurious villa in Dubai, a serene beachfront
            apartment in Abu Dhabi, or a charming countryside home in Sharjah,
            our user-friendly tool makes it effortless to find properties in
            your preferred emirate.
          </span>
          <div className="row">
            <div
              className="col-md-8"
              onClick={() => {
                navigate(`/properties?emirate=dubai`);
              }}
            >
              <div className="popular_img position-relative">
                {" "}
                <img alt="" src="images/dubai.jpg" />
                <div className="popular_img_text">
                  <a href="#">Dubai</a>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 mt_md"
            >
              <div className="popular_img position-relative"
              onClick={() => {
                navigate(`/properties?emirate=abu dhabi`);
              }}>
                {" "}
                <img alt="" src="images/abu_dhabi.jpg" />
                <div className="popular_img_text">
                  <a href="#">Abu Dhabi</a>
                </div>
              </div>


              <div className="popular_img position-relative mt"
              onClick={() => {
                navigate(`/properties?emirate=umm al quwain`);
              }}>
                {" "}
                <img
                  alt=""
                  src="images/uaq.jpg"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="popular_img_text">
                  <a href="#">Umm Al Quwain</a>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              onClick={() => {
                navigate(`/properties?emirate=fujairah`);
              }}
            >
              <div
                className="popular_img position-relative mt"
                style={{ width: "100%", height: "93%" }}
              >
                {" "}
                <img
                  className="center-crop"
                  alt=""
                  src="images/fujairah.jpg"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="popular_img_text">
                  <a href="#">Fujairah</a>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              onClick={() => {
                navigate(`/properties?emirate=sharjah`);
              }}
            >
              <div className="popular_img position-relative mt">
                {" "}
                <img alt="" src="images/sharjah.jpg" />
                <div className="popular_img_text">
                  <a href="#">Sharjah</a>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              onClick={() => {
                navigate(`/properties?emirate=Ajman`);
              }}
            >
              <div className="popular_img position-relative mt">
                {" "}
                <img alt="" src="images/ajman.jpg" />
                <div className="popular_img_text">
                  <a href="#">Ajman</a>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              onClick={() => {
                navigate(`/properties?emirate=ras al khaimah`);
              }}
            >
              <div className="popular_img position-relative mt">
                {" "}
                <img alt="" src="images/ras_al_khaimah.jpg" />
                <div className="popular_img_text">
                  <a href="#">Ras Al-Khaimah</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
