export const Categories = () => {
    return <>
    <div className="single-widgets widget_category mt30 fadeInUp wow">
                <h4>Categories</h4>
                <ul>
                <li>
                          <a href="#">Apartment/Flat</a>
                        </li>
                        <li>
                          <a href="#">Villa/House</a>
                        </li>
                        <li>
                          <a href="#">Towenhouse</a>
                        </li>
                        <li>
                          <a href="#">Penthouse</a>
                        </li>
                        <li>
                          <a href="#">Residential Building</a>
                        </li>
                        <li>
                          <a href="#">Villa Compound</a>
                        </li>
                        <li>
                          <a href="#">Residential Floor</a>
                        </li>
                        <li>
                          <a href="#">Commercial</a>
                        </li>
                        <li>
                          <a href="#">Rooms</a>
                        </li>
                        <li>
                          <a href="#">Short Term (Monthly)</a>
                        </li>
                        <li>
                          <a href="#">Short Term (Daily)</a>
                        </li>
                </ul>
              </div></>
}