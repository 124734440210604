import { useNavigate } from "react-router-dom";

export const Search = () => {
    // Actions
  const navigate = useNavigate();
  
    return (
      <>
        <div className="slider-wrap wow fadeInUp">
          <div className="container">
            <div className="sliderTxt">
              <h1>Find Your Dream Home</h1>
              <p>
                Ready to embark on your property search journey? explore a wide
                range of real estate opportunities across the emirates of the
                UAE. Your dream property is just a few clicks away.
              </p>
              <div id="exTab1" className="container">
                <ul className="nav nav-pills">
                  <li>
                    {" "}
                    <a href="#1a" className="active" data-toggle="tab">
                      Buy Property
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#2a" data-toggle="tab">
                      Rent Property
                    </a>{" "}
                  </li>
                </ul>
                <div className="tab-content clearfix">
                  <div className="tab-pane active" id="1a">
                    <div className="form-wrap">
                      <form>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="input-group origin">
                              <input
                                type="text"
                                name=" Origin"
                                placeholder="Enter Property, Location, Landmark ..."
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group destination">
                              <input
                                type="text"
                                name=" Destination"
                                placeholder="City"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Property Type</span>
                                <ul className="list">
                                  <li
                                    data-value="Property Type"
                                    data-display="Property Type"
                                    className="option selected focus"
                                  >
                                    Property Type
                                  </li>
                                  <li
                                    data-value="Residential"
                                    className="option"
                                  >
                                    Residential
                                  </li>
                                  <li
                                    data-value="Commercial"
                                    className="option"
                                  >
                                    Commercial
                                  </li>
                                  <li data-value="Land" className="option">
                                    Land
                                  </li>
                                  <li data-value="Luxury" className="option">
                                    Luxury
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 end_date">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Bedrooms</span>
                                <ul className="list">
                                  <li
                                    data-value="Bedrooms"
                                    data-display="Bedrooms"
                                    className="option selected focus"
                                  >
                                    Bedrooms
                                  </li>
                                  <li data-value="1" className="option">
                                    1
                                  </li>
                                  <li data-value="2" className="option">
                                    2
                                  </li>
                                  <li data-value="3" className="option">
                                    3
                                  </li>
                                  <li data-value="4" className="option">
                                    4
                                  </li>
                                  <li data-value="5" className="option">
                                    5
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 economy">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Bathrooms</span>
                                <ul className="list">
                                  <li
                                    data-value="Bathrooms"
                                    data-display="Bathrooms"
                                    className="option selected"
                                  >
                                    Bathrooms
                                  </li>
                                  <li data-value="1" className="option">
                                    1
                                  </li>
                                  <li data-value="2" className="option">
                                    2
                                  </li>
                                  <li data-value="3" className="option">
                                    3
                                  </li>
                                  <li data-value="4" className="option">
                                    4
                                  </li>
                                  <li data-value="5" className="option">
                                    5
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Price</span>
                                <ul className="list">
                                  <li
                                    data-value="Property Type"
                                    data-display="Property Type"
                                    className="option selected focus"
                                  >
                                    Property Price
                                  </li>
                                  <li
                                    data-value="Residential"
                                    className="option"
                                  >
                                    AED999 - AED1999
                                  </li>
                                  <li
                                    data-value="Commercial"
                                    className="option"
                                  >
                                    AED1999 - AED2999
                                  </li>
                                  <li data-value="Land" className="option">
                                    AED2999 - AED3999
                                  </li>
                                  <li data-value="Luxury" className="option">
                                    AED3999 - AED4999
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="input-btn">
                              <button className="sbutn"
                              onClick={() => {
                                navigate('/properties')
                              }}>
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>{" "}
                                Search{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="tab-pane" id="2a">
                    <div className="form-wrap">
                      <form>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="input-group origin">
                              <input
                                type="text"
                                name=" Origin"
                                placeholder="Enter Property, Location, Landmark ..."
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group destination">
                              <input
                                type="text"
                                name=" Destination"
                                placeholder="Location"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Property Type</span>
                                <ul className="list">
                                  <li
                                    data-value="Property Type"
                                    data-display="Property Type"
                                    className="option selected focus"
                                  >
                                    Property Type
                                  </li>
                                  <li
                                    data-value="Residential"
                                    className="option"
                                  >
                                    Residential
                                  </li>
                                  <li
                                    data-value="Commercial"
                                    className="option"
                                  >
                                    Commercial
                                  </li>
                                  <li data-value="Land" className="option">
                                    Land
                                  </li>
                                  <li data-value="Luxury" className="option">
                                    Luxury
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 end_date">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Bedrooms</span>
                                <ul className="list">
                                  <li
                                    data-value="Bedrooms"
                                    data-display="Bedrooms"
                                    className="option selected focus"
                                  >
                                    Bedrooms
                                  </li>
                                  <li data-value="1" className="option">
                                    1
                                  </li>
                                  <li data-value="2" className="option">
                                    2
                                  </li>
                                  <li data-value="3" className="option">
                                    3
                                  </li>
                                  <li data-value="4" className="option">
                                    4
                                  </li>
                                  <li data-value="5" className="option">
                                    5
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 economy">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Bathrooms</span>
                                <ul className="list">
                                  <li
                                    data-value="Bathrooms"
                                    data-display="Bathrooms"
                                    className="option selected"
                                  >
                                    Bathrooms
                                  </li>
                                  <li data-value="1" className="option">
                                    1
                                  </li>
                                  <li data-value="2" className="option">
                                    2
                                  </li>
                                  <li data-value="3" className="option">
                                    3
                                  </li>
                                  <li data-value="4" className="option">
                                    4
                                  </li>
                                  <li data-value="5" className="option">
                                    5
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <div
                                className="nice-select form-control wide select_option"
                                tabIndex={0}
                              >
                                <span className="current">Price</span>
                                <ul className="list">
                                  <li
                                    data-value="Property Type"
                                    data-display="Property Type"
                                    className="option selected focus"
                                  >
                                    Property Price
                                  </li>
                                  <li
                                    data-value="Residential"
                                    className="option"
                                  >
                                    AED999 - AED1999
                                  </li>
                                  <li
                                    data-value="Commercial"
                                    className="option"
                                  >
                                    AED1999 - AED2999
                                  </li>
                                  <li data-value="Land" className="option">
                                    AED2999 - AED3999
                                  </li>
                                  <li data-value="Luxury" className="option">
                                    AED3999 - AED4999
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="input-btn">
                              <button className="sbutn">
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>{" "}
                                Search{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };