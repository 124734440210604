import { useSelector } from "react-redux";
import { RecentProperty } from "../../../components/recent_product";
import { RootState } from "../../../store/rootState";

export const RecentProperties = () => {
  const { loading, properties } = useSelector(
    (state: RootState) => state.properties
  );

  return (
    <>
      <div className="single-widgets widget_category fadeInUp wow">
        <h4>Recents Property</h4>
        <ul className="property_sec">
          {[
            ...(properties ?? [])
              .slice(0, 2)
              .map((property, index) => <RecentProperty property={property} />),
          ]}
        </ul>
      </div>
    </>
  );
};
